import React, { useEffect, useState } from "react";

const ImpactGrow = ({ data }) => {
  const [isExpand, setIsExpand] = useState(-1);

  const ExpandButton = () => {
    return (
      <div className="expand_btn">
        <img src="./images/expand.svg" alt="" />
      </div>
    );
  };

  return (
    <section className="impact_grow">
      <div className="container">
        <div className="head">
          <h3>
            {data.basic.basic_title}{" "}
            <strong>{data.basic.basic_title_dark}</strong>
          </h3>
        </div>
      </div>

      <div className="impact_accordion">
        {data.items.map((val, index) => {
          return (
            <div
              className={`${isExpand === index ? "expand" : ""} card `}
              style={{
                background: `url('https://www.app.creasionventures.com/storage/${val.image}')`,
              }}
              key={index}
              onClick={() => setIsExpand(index)}
            >
              <div className="content">
                <article className="title">
                  <h4>{val.title}</h4>
                  <img src={`/storage/${val.image}`} alt="" />
                </article>
                <div className="counter">
                  <h2>
                    {val.number} <span>{val.number_unit}</span>{" "}
                  </h2>
                  <p>{val.text}</p>
                </div>

                {isExpand != index ? <ExpandButton /> : ""}
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default ImpactGrow;
