import React, { useState, useEffect } from "react";
import { Slider } from "antd";
import "antd/dist/antd.css";

const ImapctSlider = (data) => {
  const [slideNum, setSlideNum] = useState(1);

  let basicData = data.data.basic;
  let sliderData = data.data.items;

  function loadData(value) {
    setSlideNum(value);
  }

  function valueChange(val) {
    return (val * slideNum).toFixed(1);
  }

  useEffect(() => {
    const sliderBtn = document.querySelector(
      ".slider_range .ant-slider-handle"
    );
    const sliderText = `Tons of plastic recycled`;

    let p = document.createElement("p");
    p.innerText = sliderText;

    sliderBtn.appendChild(p);
  }, []);

  return (
    <>
      <section className="home_slider">
        <div className="container">
          <div className="head">
            <h3>
              {basicData.basic_title_light}
              <strong> {basicData.basic_title_dark}</strong>
            </h3>

            <p>{basicData.basic_text}</p>
          </div>

          <div className="wrap">
            {sliderData.map((val, index) => {
              return (
                <div className="num_wrap" key={index}>
                  <div className="num_flex">
                    <div className="inum">
                      <h4>{valueChange(val.number, null)}</h4>
                      <h6>{val.unit}</h6>
                    </div>

                    <div className="icon">
                      <img
                        src={`https://app.recyclersaathi.org/storage/${val.icon}`}
                        alt=""
                      />
                    </div>
                  </div>

                  <p>{val.title_text}</p>
                </div>
              );
            })}
          </div>

          <div className="slider_range">
            <Slider
              min={1}
              max={basicData.basic_impact_number_till_now}
              tooltipVisible
              onChange={loadData}
            />
          </div>
        </div>
      </section>
    </>
  );
};

export default ImapctSlider;
