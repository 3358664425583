import React, { useEffect, useState } from "react";
import "./about.scss";
import ScrollTop from "../../components/ScrollTop";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

import Objective from "../../components/Objective";

import Preloader from "../../components/Preloader/Preloader";
import BannerHalf from "../../components/Banner/BannerHalf";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-cards";

import { EffectCards } from "swiper";
import { Helmet } from "react-helmet";

gsap.registerPlugin(ScrollTrigger);

const About = () => {
  const [aboutData, setAboutData] = useState([]);

  const fetchData = () => {
    fetch("https://www.app.creasionventures.com/api/about-us/about")
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        let aboutapi = data.data;

        setAboutData(aboutapi);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      {aboutData.about ? (
        <div>
          <ScrollTop />
          <BannerHalf
            image={`url('https://www.app.creasionventures.com/storage/${aboutData.about.basic.basic_banner_image}')`}
            subtitle={aboutData.about.basic.basic_banner_title}
            title={`<h3><strong>${aboutData.about.basic.basic_banner_subtitle}</strong> </h3>`}
          />

          <div className="about_text">
            <div className="container">
              <div className="text">
                <h3>
                  {aboutData.about.basic.basic_title_light}{" "}
                  <strong>{aboutData.about.basic.basic_title_dark} </strong>
                </h3>

                <article
                  dangerouslySetInnerHTML={{
                    __html: aboutData.about.basic.basic_description,
                  }}
                ></article>
              </div>
            </div>
          </div>

          <div className="mvv">
            <div className="container">
              <div className="wrap">
                <div className="text">
                  <div className="card">
                    <h3>
                      <strong>Mission</strong>
                    </h3>

                    <article
                      dangerouslySetInnerHTML={{
                        __html: aboutData.about.basic.basic_mission,
                      }}
                    ></article>
                  </div>

                  <div className="card">
                    <h3>
                      <strong>Vision</strong>
                    </h3>
                    <article
                      dangerouslySetInnerHTML={{
                        __html: aboutData.about.basic.basic_vision,
                      }}
                    ></article>
                  </div>

                  <div className="card">
                    <h3>
                      <strong>Goals</strong>
                    </h3>
                    <article
                      dangerouslySetInnerHTML={{
                        __html: aboutData.about.basic.basic_goals,
                      }}
                    ></article>
                  </div>
                </div>

                <div className="img">
                  <Swiper
                    effect={"cards"}
                    grabCursor={true}
                    modules={[EffectCards]}
                    className="mySwiper"
                  >
                    {JSON.parse(
                      aboutData.about.basic.basic_mission_vision_goal_images
                    ).map((val, index) => {
                      return (
                        <SwiperSlide key={index}>
                          <img
                            src={`https://www.app.creasionventures.com/storage/${val}`}
                            alt=""
                          />
                        </SwiperSlide>
                      );
                    })}
                  </Swiper>
                </div>
              </div>
            </div>
          </div>

          <div className="abt_objective">
            <Objective data={aboutData.about} />
          </div>
        </div>
      ) : (
        <Preloader />
      )}
    </>
  );
};

export default About;
