import React, { useState, useRef } from "react";
import { Row, Col } from "antd";
import BannerHalf from "../../components/Banner/BannerHalf";
import Preloader from "../../components/Preloader/Preloader";
import "./carrer.scss";
import ScrollTop from "../../components/ScrollTop";

import { Form, Input, Button, InputNumber, notification } from "antd";

import axios from "axios";
import { useEffect } from "react";

const BailingPartner = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [pageData, setPageData] = useState({
    items: null,
  });

  const myForm = useRef();

  const fetchData = () => {
    fetch("https://www.app.creasionventures.com/api/careers")
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        let careerApi = data.data;

        setPageData({
          items: careerApi.career.items[0],
        });

        setIsLoading(false);
      });
  };

  const onFinish = (values) => {
    values.formData.form_type = "baling_partner";

    setIsLoading(true);

    axios
      .post("https://www.app.creasionventures.com/api/contact", values)
      .then((response) => {
        if (response.data.errors) {
          response.data.errors.forEach((error) => {
            notification["error"]({
              description: error,
            });
          });
        } else {
          notification["success"]({
            description: response.data.message,
          });

          myForm.current.resetFields();
        }

        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);
  return (
    <>
      <ScrollTop />

      {isLoading ? <Preloader /> : ""}

      {pageData.items ? (
        <BannerHalf
          image={`url('https://www.app.creasionventures.com/storage/${pageData.items.image}')`}
          subtitle="Carrer"
          title={`<h3><strong>${pageData.items.title}</strong></h3>`}
        />
      ) : (
        ""
      )}

      <div className="form_wrapper">
        <div className="container">
          <div className="head">
            {pageData.items ? (
              <>
                <h3>
                  {pageData.items.title_light}
                  <strong> {pageData.items.title_dark}</strong>
                </h3>

                <article
                  dangerouslySetInnerHTML={{
                    __html: pageData.items.body_description,
                  }}
                ></article>
              </>
            ) : (
              ""
            )}
          </div>

          <div className="form">
            <Form name="Bailing Partner" ref={myForm} onFinish={onFinish}>
              <div className="form_card">
                <Form.Item
                  initialValue={`Baling Partner`}
                  name={["formData", "type"]}
                  hidden={true}
                >
                  <Input />
                </Form.Item>
              </div>
              <Row>
                <Col span={12}>
                  <div className="form_card">
                    <Form.Item
                      name={["formData", "full_name"]}
                      label="Name of individual/organization"
                      rules={[
                        {
                          required: true,
                          message:
                            "Please input your name of Individual / Organization!",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </div>
                </Col>

                <Col span={12}>
                  <div className="form_card">
                    <Form.Item
                      name={["formData", "contact_number"]}
                      label="Phone Number"
                      rules={[
                        {
                          required: true,
                          message: "Please input your contact Number!",
                        },
                      ]}
                    >
                      <InputNumber />
                    </Form.Item>
                  </div>
                </Col>

                <Col span={12}>
                  <div className="form_card">
                    <Form.Item
                      name={["formData", "address"]}
                      label="Address"
                      rules={[
                        {
                          required: true,
                          message: "Please input your address location!",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </div>
                </Col>

                <Col span={12}>
                  <div className="form_card">
                    <Form.Item
                      name={["formData", "email"]}
                      label="Email"
                      rules={[
                        {
                          required: true,
                          type: "email",
                          message: "Please input your email address!",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </div>
                </Col>

                <Col span={24}>
                  <div className="form_card">
                    <Form.Item
                      name={["formData", "message"]}
                      label="Your Message"
                    >
                      <Input.TextArea />
                    </Form.Item>
                  </div>
                </Col>
              </Row>

              <div className="submit_btn">
                {/* <button onClick={submitRequest}>Submit Request</button> */}
                <Form.Item>
                  <Button type="primary" htmlType="submit">
                    Submit request
                  </Button>
                </Form.Item>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
};

export default BailingPartner;
