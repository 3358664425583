import React, { useState, useEffect, useRef } from "react";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import BannerHalf from "../../components/Banner/BannerHalf";
import TimelineImage from "./TimelineImage";
import ScrollTop from "../../components/ScrollTop";

import "./plastic.scss";
import Preloader from "../../components/Preloader/Preloader";
import { Helmet } from "react-helmet";
gsap.registerPlugin(ScrollTrigger);

const AboutPlastics = () => {
  const headerRefs = useRef([]);
  useEffect(() => {
    headerRefs.current.forEach((el, index) => {
      const head = el.querySelector(".head");
      ScrollTrigger.refresh();

      if (window.innerWidth > 500) {
        gsap.to(head, {
          scrollTrigger: {
            trigger: el,
            start: "top top",
            pin: head,
            end: "bottom center",
          },
        });
      }
    });

    let itemsImg = document.querySelectorAll(".pl_product");
    itemsImg.forEach((item, index) => {
      gsap.to(item, {
        scale: "random(0.95, 1)",
        rotation: "+=" + "random(-3, 3)",
        duration: "random(0.5, 1)", // NO refresh!
        ease: "random(none, power2, power1.inOut)",
        repeat: -1,
        yoyo: true,
        repeatRefresh: true,
      });
    });
  });

  const [plasticData, setPlasticData] = useState([]);
  const fetchData = () => {
    fetch("https://www.app.creasionventures.com/api/plastic")
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        let plasticApi = data.data;

        setPlasticData(plasticApi);
      });
  };

  const plData = plasticData;

  useEffect(() => {
    fetchData();
  }, []);
  headerRefs.current = [];

  const addToRefs = (el) => {
    if (el && !headerRefs.current.includes(el)) {
      headerRefs.current.push(el);
    }
  };

  let introData = [];

  if (plData.aboutPlastic) {
    let firstSection = plData.aboutPlastic.details;
    let fifthSection = plData.aboutPlastic.fifthSection;
    let thirdSection = plData.aboutPlastic.thirdSection;
    let fourthSection = plData.aboutPlastic.fourthSection;

    const PlasticClassification = (data) => {
      let pcData = data.data.items;

      return (
        <>
          <Helmet>
            <meta charSet="utf-8" />
            <title>About Plastic | Recycler Saathi</title>
            <meta
              name="description"
              content="When it comes to plastic recycling, every action counts!"
            />
            <link
              rel="canonical"
              href="https://recyclersaathi.org/about_plastics"
            />
          </Helmet>

          <div className="pc_wrap">
            <article
              dangerouslySetInnerHTML={{ __html: data.data.basic.basic_text }}
            ></article>

            {pcData.map((val, index) => {
              return (
                <div className="pc_card" key={index}>
                  <div className="pc_head">
                    <img
                      src={`https://www.app.creasionventures.com/storage/${val.icon}`}
                      alt=""
                    />

                    <div className="title">
                      <h5>{val.subtitle}</h5>
                      <h4>{val.title}</h4>
                      <article
                        dangerouslySetInnerHTML={{ __html: val.text }}
                      ></article>
                    </div>
                  </div>

                  <img
                    className="pl_product"
                    src={`https://www.app.creasionventures.com/storage/${val.image}`}
                    alt=""
                  />
                </div>
              );
            })}
          </div>
        </>
      );
    };

    introData = [
      {
        title: `<h3><strong>${firstSection.section_first_title_dark} </strong>${firstSection.section_first_title_light} </h3>`,
        subtitle: firstSection.section_first_subtitle,
        content: firstSection.section_first_description,
      },

      {
        title: `<h3><strong>${firstSection.section_second_title_dark} </strong>${firstSection.section_second_title_light} </h3>`,
        subtitle: firstSection.section_second_subtitle,
        content: firstSection.section_second_description,
      },
      {
        title: `<h3><strong>${thirdSection.basic.basic_title_dark}</strong> ${thirdSection.basic.basic_title_light} </h3>`,
        subtitle: thirdSection.basic.basic_subtitle,
        contentDifferent: <TimelineImage data={thirdSection} />,
      },
      {
        title: `<h3><strong>${fourthSection.basic.basic_title_dark} </strong>${fourthSection.basic.basic_title_light} </h3>`,
        subtitle: fourthSection.basic.basic_subtitle,
        contentDifferent: <PlasticClassification data={fourthSection} />,
      },
      {
        title: `<h3><strong>${fifthSection.basic.basic_title_dark} </strong>${fifthSection.basic.basic_title_light} </h3>`,
        subtitle: "",
        content: `
                    ${fifthSection.basic.basic_description}

            `,
      },
    ];
  }

  return (
    <>
      <ScrollTop />
      {plasticData.aboutPlastic ? (
        <div>
          <BannerHalf
            image="url(./images/plastic_introduction.jpg)"
            subtitle={plData.aboutPlastic.details.banner_subtitle}
            title={`<h3>${plData.aboutPlastic.details.section_first_title_dark} <strong>${plData.aboutPlastic.details.section_first_title_light} </strong> </h3>`}
          />

          <section className="plastic_intro">
            {introData.map((val, index) => {
              return (
                <div className="p_card " key={index} ref={addToRefs}>
                  <div className="container">
                    <div className="card_wrap">
                      <div className="head">
                        <h6>{val.subtitle} </h6>
                        <article
                          dangerouslySetInnerHTML={{ __html: val.title }}
                        ></article>
                      </div>

                      <div className="content">
                        {val.contentDifferent ? (
                          val.contentDifferent
                        ) : (
                          <article
                            dangerouslySetInnerHTML={{ __html: val.content }}
                          ></article>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </section>
        </div>
      ) : (
        <Preloader />
      )}
    </>
  );
};

export default AboutPlastics;
