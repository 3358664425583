import React, { useState, useEffect } from "react";
import BannerHalf from "../../components/Banner/BannerHalf";
import ScrollTop from "../../components/ScrollTop";
import "./plastic.scss";

import Preloader from "../../components/Preloader/Preloader";
import axios from "axios";

const WWWV = () => {
  const [wwData, setWwData] = useState([]);

  const fetchData = () => {
    axios
      .get("https://www.app.creasionventures.com/api/plastic")
      .then((response) => {
        setWwData(response.data.data.worldWithoutWasteVision);
      })

      .catch((error) => console.log(error));
  };

  useEffect(() => {
    fetchData();
  }, []);

  console.log(wwData);

  return (
    <>
      <ScrollTop />

      {wwData.id ? (
        <div>
          <BannerHalf
            image={`url(https://www.app.creasionventures.com/storage/${wwData.banner_image})`}
            subtitle={wwData.banner_subtitle}
            title={`<h3>${wwData.banner_title_light}<strong> ${wwData.banner_title_dark}</strong> </h3>`}
          />

          <section className="wwvi intro">
            <div className="container">
              <div className="head">
                <h3>
                  <strong>{wwData.section_one_title_dark}</strong>{" "}
                  {wwData.section_one_title_light}
                </h3>
                <article
                  dangerouslySetInnerHTML={{
                    __html: wwData.section_one_text,
                  }}
                ></article>
              </div>
            </div>
          </section>

          <section className="wwvi full_image">
            <img
              src={`https://www.app.creasionventures.com/storage/${wwData.section_two_image}`}
              alt=""
            />
          </section>

          <section className="wwvi text_cr">
            <div className="container">
              <article
                dangerouslySetInnerHTML={{
                  __html: wwData.section_three_text,
                }}
              ></article>
            </div>
          </section>

          <section className="wwvi video_section">
            <div className="container">
              <div className="video">
                <iframe
                  src={wwData.section_four_youtube_link}
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
          </section>
        </div>
      ) : (
        <Preloader />
      )}
    </>
  );
};

export default WWWV;
