import React, { useState, useEffect } from "react";
import BannerHalf from "../../components/Banner/BannerHalf";
import ScrollTop from "../../components/ScrollTop";
import { CloseOutlined } from "@ant-design/icons";

import "./stories.scss";

import Preloader from "../../components/Preloader/Preloader";

const Stories = () => {
  const [storyData, setStoryData] = useState({
    storyBasic: null,
    storyItems: null,
  });

  const [isLoading, setIsLoading] = useState(true);

  const [popupState, setPopupState] = useState({ open: false });

  const fetchData = () => {
    fetch("https://www.app.creasionventures.com/api/stories")
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        let storyApi = data.data;

        setStoryData({
          storyBasic: storyApi.story.basic,
          storyItems: storyApi.story.items,
        });

        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const Popup = (val, onclick) => {
    return (
      <section
        className="story_popup"
        onClick={() => setPopupState({ open: false })}
      >
        <div className="wrap">
          <span className="close">
            <CloseOutlined />
          </span>

          <div className="img">
            <img
              src={`https://www.app.creasionventures.com/storage/${val.val.img}`}
              alt=""
            />
          </div>

          <div className="text">
            <h3>{val.val.name}</h3>
            <article
              dangerouslySetInnerHTML={{ __html: val.val.story }}
            ></article>
          </div>
        </div>
      </section>
    );
  };

  function showPopup(val) {
    return () => setPopupState({ open: true, val });
  }

  return (
    <>
      <ScrollTop />

      {!isLoading ? (
        <div>
          <BannerHalf
            image={`url('https://www.app.creasionventures.com/storage/${storyData.storyBasic.basic_banner_image}')`}
            subtitle="Resources"
            title={`<h3><strong>${storyData.storyBasic.basic_title}</strong> </h3>`}
          />

          <section className="story_list">
            <div className="container">
              <div className="wrap">
                {storyData.storyItems.map((val, index) => {
                  return (
                    <div
                      className="story_card"
                      key={index}
                      onClick={showPopup(val)}
                    >
                      <a href="#">
                        <figure>
                          <img
                            src={`https://www.app.creasionventures.com/storage/${val.img}`}
                            alt=""
                          />
                        </figure>

                        <div className="detail">
                          <h4>{val.name}</h4>
                          <article
                            dangerouslySetInnerHTML={{ __html: val.story }}
                          ></article>

                          <h5 className="read-more">Read More</h5>
                        </div>
                      </a>
                    </div>
                  );
                })}
              </div>
            </div>
          </section>

          {popupState.open === true && <Popup val={popupState.val} />}
        </div>
      ) : (
        <Preloader />
      )}
    </>
  );
};

export default Stories;
