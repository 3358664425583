


const dataStyle = {
  id: "dataStyle",
  type: "circle",
  filter: ["==", "title", `Collection Partners`],
  paint: {
    "circle-radius": 8,
    "circle-stroke-width": 1,
    "circle-color": [
      "match",
      ["get", "title"],
      "Organisational Network",
      "#EA5C2B",
      "PET recycling center",
      "#D9CE3F",
      "Recycler Saathi Center",
      "#10b5c9",
      "Volunteer For Change",
      "#573391",
      "Collection Partners",
      "#EF6D6D ",
      "Waste Smart Schools",
      "#219F94",
      "Waste Smart Musuem",
      "red",
      /* other */ "#BD1E1E",
    ],
  },
};

const provinceLabelStyle = {
  id: "provinceLabel",
  type: "symbol",
  layout: {
    "text-allow-overlap": false,
    "text-field": ["get", "description"],
    "text-variable-anchor": ["top", "bottom", "left", "right"],
    "text-radial-offset": 0.5,
    "text-justify": "auto",
    "text-size": 12,
    "text-font": ["Open Sans Bold", "Arial Unicode MS Bold"],
  },
  paint: {
    "text-color": "#003755",
  },
};

const mapStyleLine = {
  id: "map_style",
  type: "line",
  layout: {},
  paint: {
    "line-color": "#003755",
    "line-width": 1.2,
    // "line-dasharray": [2, 1],
  },
};

export { dataStyle, provinceLabelStyle, mapStyleLine };
