import React from "react";
import { NavLink } from "react-router-dom";

import "./footer.scss";

import {
  FacebookOutlined,
  InstagramOutlined,
  TwitterOutlined,
  LinkedinOutlined,
} from "@ant-design/icons";

const Footer = () => {
  const date = new Date();
  let year = date.getFullYear();

  return (
    <>
      <footer>
        <div className="footer_last">
          <div className="container">
            <div className="wrap">
              <div className="footer_contact">
                <div className="legal_detail ">
                  <NavLink to="/">
                    <img src="./images/creasion-venture.png" />
                  </NavLink>
                  <h4>Creasion Ventures Inc. Pvt. Ltd </h4>
                  <p>VAT No. 606538451</p>
                </div>

                <div className="legal_detail ">
                  <h4>CONTACT US</h4>
                  {/* <p>House No. 260,</p> */}
                  <p>Pabitra Pyara Marg, Baluwatar, Kathmandu</p>
                  <p>Tel: 01-4520784, 01-4540085 </p>
                  <p>Email: info@creasionventures.com </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="footer_bottom">
                <div className="container">
                    <div className="wrap">
                        <div className="logo_support">
                            <div className="img">
                                <h4>An initiative of</h4>
                                <img src="./images/creasion.svg" alt="" />
                            </div>

                            <div className="img">
                                <h4>Supported by</h4>
                                <img src="./images/cococola_support.svg" alt="" />
                            </div>                            
                        </div>
                    </div>
                </div>
            </div> */}

        <div className="footer_copy">
          <div className="container">
            <div className="wrap">
              <p>
                © {year}{" "}
                <a href="https://creasion.org/" target="_b">
                  Creasion Ventures.{" "}
                </a>{" "}
              </p>
              <p className="copyright">
                Powered by{" "}
                <a href="https://hueshine.com/" target="_blank">
                  Hue Shine
                </a>
              </p>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
