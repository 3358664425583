import React, { useEffect, useState } from "react";
import BannerHalf from "../../components/Banner/BannerHalf";
import ScrollTop from "../../components/ScrollTop";
import Preloader from "../../components/Preloader/Preloader";
import "./plastic.scss";
import axios from "axios";

const PlasticIndustry = () => {
  const [piData, setPiData] = useState([]);
  const [firstSection, setFirstSection] = useState([]);
  const [secondSection, setSecondSection] = useState([]);

  const fetchData = () => {
    axios
      .get("https://www.app.creasionventures.com/api/plastic")
      .then((response) => {
        setPiData(response.data.data.plasticIndustry);
        setFirstSection(response.data.data.plasticIndustry.firstSection);
        setSecondSection(response.data.data.plasticIndustry.secondSection);
      })

      .catch((error) => console.log(error));
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <ScrollTop />

      {secondSection.items ? (
        <div>
          <BannerHalf
            image={`url(https://www.app.creasionventures.com/storage/${piData.details.banner_image})`}
            subtitle={piData.details.banner_subtitle}
            title={`<h3>${piData.details.banner_title_dark} <strong>${piData.details.banner_title_light}</strong> </h3>`}
          />

          <section className="r_process pi_module">
            <div className="container">
              <div className="head">
                <h3>
                  <strong>{firstSection.basic.basic_title_dark} </strong>
                  {firstSection.basic.basic_title_light}
                </h3>

                <article
                  dangerouslySetInnerHTML={{
                    __html: firstSection.basic.basic_description,
                  }}
                ></article>
              </div>

              <div className="proces_step">
                {firstSection.items.map((val, index) => {
                  return (
                    <div className="step" key={index}>
                      <div className="img">
                        <img
                          src={`https://www.app.creasionventures.com/storage/${val.image}`}
                          alt=""
                        />
                      </div>
                      <div className="text">
                        <h4>{val.title}</h4>

                        <article
                          dangerouslySetInnerHTML={{
                            __html: val.text,
                          }}
                        ></article>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </section>

          {secondSection.items.map((val, index) => {
            return (
              <section className="global_plastic pi_module" key={index}>
                <div className="container">
                  <div className="wrap">
                    <div className="text">
                      <div className="head">
                        <h3>
                          <strong>{val.title_dark}</strong> {val.title_light}{" "}
                        </h3>

                        <article
                          dangerouslySetInnerHTML={{
                            __html: val.description,
                          }}
                        ></article>
                      </div>
                    </div>

                    <div className="chart">
                      <img
                        src={`https://www.app.creasionventures.com/storage/${val.image}`}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </section>
            );
          })}
        </div>
      ) : (
        <Preloader />
      )}
    </>
  );
};

export default PlasticIndustry;
