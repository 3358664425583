import React, { useEffect, useState } from "react";

import { CalendarOutlined } from "@ant-design/icons";

import BannerHalf from "../../components/Banner/BannerHalf";
import ScrollTop from "../../components/ScrollTop";

import BlogsData from "./BlogsData";

import { NavLink } from "react-router-dom";

import Preloader from "../../components/Preloader/Preloader";

import "./blogs.scss";

const Blogs = () => {
  const [blogData, setBlogData] = useState({
    blogBasic: null,
    blogItems: null,
  });

  const [isLoading, setIsLoading] = useState(true);

  const fetchData = () => {
    fetch("https://www.app.creasionventures.com/api/blogs")
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        let blogApi = data.data;

        setBlogData({
          blogBasic: blogApi.blog.basic,
          blogItems: blogApi.blog.items,
        });
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      {!isLoading ? (
        <div>
          <ScrollTop />

          <>
            <BannerHalf
              image={`url('https://www.app.creasionventures.com/storage/${blogData.blogBasic.basic_banner_image}')`}
              subtitle="Resources"
              title={`<h3><strong>${blogData.blogBasic.basic_title}</strong> </h3>`}
            />

            <section className="blogs_list">
              <div className="container">
                <div className="list_wrap">
                  {blogData.blogItems.map((val, index) => {
                    return (
                      <div className="blog_card" key={index}>
                        <NavLink to="/blog_detail" state={{ detail: val }}>
                          <figure>
                            <img
                              src={`https://www.app.creasionventures.com/storage/${val.image}`}
                              alt=""
                            />
                          </figure>

                          <div className="detail">
                            <h4>{val.title}</h4>
                            <p>
                              By {val.author} <CalendarOutlined />{" "}
                              {val.published_date}
                            </p>
                          </div>
                        </NavLink>
                      </div>
                    );
                  })}
                </div>
              </div>
            </section>
          </>
        </div>
      ) : (
        <Preloader />
      )}
    </>
  );
};

export default Blogs;
