import React, { useState, useEffect } from "react";
import BannerHalf from "../../components/Banner/BannerHalf";
import ScrollTop from "../../components/ScrollTop";

import { NavLink } from "react-router-dom";
import Preloader from "../../components/Preloader/Preloader";

import "./media.scss";

const Media = () => {
  const [mediaData, setMediaData] = useState({
    mediaBasic: null,
    mediaItems: null,
  });

  const [isLoading, setIsLoading] = useState(true);

  const fetchData = () => {
    fetch("https://www.app.creasionventures.com/api/media")
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        let mediaApi = data.data;

        setMediaData({
          mediaBasic: mediaApi.media.basic,
          mediaItems: mediaApi.media.items,
        });

        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      {!isLoading ? (
        <div>
          <ScrollTop />

          <BannerHalf
            image={`url('https://www.app.creasionventures.com/storage/${mediaData.mediaBasic.basic_banner_image}')`}
            subtitle="Resources"
            title={`<h3><strong>${mediaData.mediaBasic.basic_title}</strong> </h3>`}
          />

          <section className="media_lists">
            <div className="container">
              <div className="media_card_wrap">
                {mediaData.mediaItems.map((val, index) => {
                  return (
                    <div className="media_card" key={index}>
                      <NavLink
                        className="news_wrap"
                        to="/media_detail"
                        state={{ detail: val }}
                      >
                        <img
                          src={`https://www.app.creasionventures.com/storage/${val.image}`}
                          alt=""
                        />
                        <div className="desc">
                          <h4>{val.title}</h4>
                          <p>{val.date}</p>
                        </div>
                      </NavLink>
                    </div>
                  );
                })}
              </div>
            </div>
          </section>
        </div>
      ) : (
        <Preloader />
      )}
    </>
  );
};

export default Media;
