import React from "react";
import { useLocation } from "react-router-dom";
import ScrollTop from "../../components/ScrollTop";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";

import { Autoplay } from "swiper";
import Moment from "react-moment";

import "./news.scss";

const NewsDetail = () => {
  const location = useLocation();
  const { detail } = location.state;

  console.log(JSON.parse(detail.image));
  const ImageObj = (images) => {
    let imageArray = JSON.parse(images);

    return (
      <>
        <Swiper
          slidesPerView={1}
          loop={true}
          className="mySwiper"
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          modules={[Autoplay]}
        >
          {imageArray.map((val, index) => {
            return (
              <SwiperSlide key={index}>
                <img
                  src={`https://www.app.creasionventures.com/storage/${val}`}
                  alt=""
                />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </>
    );
  };
  return (
    <>
      <ScrollTop />

      <section className="news_detail">
        <div className="wrap">
          <div className="nd_head">
            <h4>{detail.title} </h4>

            <Moment format="YYYY/MM/DD">{detail.published_date}</Moment>
          </div>

          <div className="nd_figure">
            {ImageObj(detail.image)}
            {/* <img
              src={`https://www.app.creasionventures.com/storage/${detail.image}`}
              alt=""
            /> */}
          </div>

          <div className="nd_text">
            <article
              dangerouslySetInnerHTML={{ __html: detail.text }}
            ></article>
          </div>
        </div>
      </section>
    </>
  );
};

export default NewsDetail;
