import React, { useEffect, useState } from "react";
import HomeAbout from "./HomeAbout";

import { NavLink } from "react-router-dom";

import ImapctSlider from "../../components/ImapctSlider";
import "./home.scss";
import ImpactGrow from "./ImpactGrow";
import HomeNews from "./HomeNews";

import InstagramFeed from "react-ig-feed";
import "react-ig-feed/dist/index.css";

import Preloader from "../../components/Preloader/Preloader";

import HomeVideos from "./HomeVideos";

import { Helmet } from "react-helmet";

const Home = () => {
  const [homeData, setHomeData] = useState([]);
  const fetchData = () => {
    fetch("https://www.app.creasionventures.com/api/homepage")
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        let homeapi = data.data;

        setHomeData(homeapi);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      {homeData.banner ? (
        <div>
          <section className="banner">
            <video className="videoTag" autoPlay loop muted>
              <source src={homeData.banner.video_link} type="video/mp4" />
            </video>

            <div className="container">
              <div className="text">
                <h2>{homeData.banner.title}</h2>

                <article
                  dangerouslySetInnerHTML={{ __html: homeData.banner.text }}
                ></article>

                <NavLink to="/about">Learn More</NavLink>
              </div>
            </div>
          </section>

          <HomeAbout data={homeData.intro} />
          <ImpactGrow data={homeData.impact} />
          <ImapctSlider data={homeData.impactSlider} />
          {/* <HomeNews /> */}
          {/* <FeaturedNews /> */}
          {/* <HomeVideos data={homeData.videosection} /> */}

          {/* <div className="insta_feed">
            <div className="head">
              <h3>
                follow us on instagram <strong>Recycler Saathi</strong>{" "}
              </h3>
            </div>

            <InstagramFeed
              token="IGQVJYc3dlZA0FmTWI4X1RJTkg5ckFWa3pqWmg4NEVDa2lJSzVHXzFBaXI3NWJGTjdtRTM1aGlBSFRILUI3UXdEZA2ZA3bFZApMzBob3FhZAGIyQjZADMV92MjR0RjNPaFF1SHB5cVVWdUl6eXB0YVFHUC0ycAZDZD"
              counter="4"
            />
          </div> */}

          {/* <section className="suscribe">
            <div className="container">
              <div className="wrap">
                <div className="newsletter">
                  <h3>
                    Join Our <strong>Newsletter</strong>
                  </h3>
                  <p>It’s so wonderful you’ll be joining our community!</p>
                  <iframe
                    src="https://creasion.substack.com/embed"
                    height="80"
                    // style="border:1px solid #EEE; background:white;"
                    style={{ background: "#10b5c9" }}
                    frameBorder="0"
                    scrolling="no"
                  ></iframe>
                </div>

                <div className="socail_icons">
                  <div className="card">
                    <a
                      href="https://www.youtube.com/channel/UCNA27e_7vPFfmrDb5pNt9vQ"
                      target="_blank"
                    >
                      <i className="fa-brands fa-youtube"></i>
                      <p>Suscribe</p>
                    </a>
                  </div>
                  <div className="card">
                    <a href="https://twitter.com/creasionorg" target="_blank">
                      <i className="fa-brands fa-twitter"></i>
                      <p>Follow</p>
                    </a>
                  </div>
                  <div className="card">
                    <a
                      href="https://www.instagram.com/recyclersaathi/"
                      target="_blank"
                    >
                      <i className="fa-brands fa-instagram"></i>
                      <p>Follow</p>
                    </a>
                  </div>
                  <div className="card">
                    <a
                      href="https://www.facebook.com/recyclersaathi"
                      target="_blank"
                    >
                      <i className="fa-brands fa-facebook-f"></i>
                      <p>Like</p>
                    </a>
                  </div>
                  <div className="card">
                    <a
                      href="https://www.linkedin.com/company/creasion/"
                      target="_blank"
                    >
                      <i className="fa-brands fa-linkedin-in"></i>
                      <p>Follow</p>
                    </a>
                  </div>
                  <div className="card">
                    <a href="https://medium.com/@vfcnepal" target="_blank">
                      <i className="fa-brands fa-medium"></i>
                      <p>Follow</p>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section> */}

          {/* <BecomeRecycler /> */}
        </div>
      ) : (
        <Preloader />
      )}
    </>
  );
};

export default Home;
