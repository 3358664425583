import React, { useEffect } from "react";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const Objective = (obData) => {
  const data = obData.data.objectives;

  const basicData = obData.data.basic;

  return (
    <section className="objectives">
      <div className="objective_head">
        <h3>
          {basicData.basic_objective_title_light}
          <strong> {basicData.basic_objective_title_dark}</strong>{" "}
        </h3>

        <article
          dangerouslySetInnerHTML={{
            __html: basicData.basic_objective_description,
          }}
        ></article>
      </div>

      <div className="container">
        <div className="wrap">
          {data.map((val, index) => {
            return (
              <div className="card" key={index}>
                <div className="content">
                  <h1>0{index + 1}</h1>
                  <div className="head">
                    <img src={`${val.icon}`} alt="" />

                    <h2>{val.title} </h2>
                  </div>

                  <article
                    dangerouslySetInnerHTML={{ __html: val.description }}
                  ></article>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default Objective;
