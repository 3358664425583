import React, { useEffect, useState } from "react";
import ScrollTop from "../../components/ScrollTop";
import { NavLink } from "react-router-dom";

import BannerHalf from "../../components/Banner/BannerHalf";
import "./about.scss";

import Preloader from "../../components/Preloader/Preloader";
import { Helmet } from "react-helmet";

const Programs = () => {
  const [programData, setProgramData] = useState([]);
  const fetchData = () => {
    fetch("https://www.app.creasionventures.com/api/about-us/our-programs")
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        let programapi = data.data;

        setProgramData(programapi.ourProgram);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      {programData.basic ? (
        <div>
          <Helmet>
            <meta charSet="utf-8" />
            <title>Programs | Recycler Saathi</title>
            <meta
              name="description"
              content="When it comes to plastic recycling, every action counts!"
            />
            <link rel="canonical" href="https://recyclersaathi.org/programs" />
          </Helmet>

          <ScrollTop />
          <BannerHalf
            image={`url('https://www.app.creasionventures.com/storage/${programData.basic.basic_banner_image}')`}
            subtitle={programData.basic.basic_banner_subtitle}
            title={`<h3><strong>${programData.basic.basic_banner_title}</strong> </h3>`}
          />

          <section className="programs">
            <div className="container">
              {programData.items.map((program, index) => {
                return (
                  <div className="pg_card" key={index}>
                    <NavLink
                      className="news_wrap"
                      to="/programDetail"
                      state={{ detail: program }}
                    >
                      <div className="img">
                        <img
                          src={`https://www.app.creasionventures.com/storage/${program.image}`}
                          alt=""
                        />
                      </div>

                      <div className="text">
                        <h3>{program.title}</h3>

                        <p>{program.short_description}</p>

                        <div className="read_more">
                          <h6>Read More</h6>
                        </div>
                      </div>
                    </NavLink>
                  </div>
                );
              })}
            </div>
          </section>
        </div>
      ) : (
        <Preloader />
      )}
    </>
  );
};

export default Programs;
