import React, { useEffect, useState } from "react";

import BannerHalf from "../../components/Banner/BannerHalf";

import ScrollTop from "../../components/ScrollTop";
import BecomeRecycler from "../../components/Footer/BecomeRecycler";

import { NavLink } from "react-router-dom";

import Preloader from "../../components/Preloader/Preloader";
import { Tabs } from "antd";
const onChange = (key) => {
  console.log(key);
};

const Careers = () => {
  const [careerData, setCareerData] = useState({
    basic: null,
    items: null,
    jobs: null,
  });

  const [isLoading, setIsLoading] = useState(true);

  const fetchData = () => {
    fetch("https://www.app.creasionventures.com/api/careers")
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        let careerApi = data.data;

        setCareerData({
          basic: careerApi.career.basic,
          items: careerApi.career.items,
          jobs: careerApi.career.jobs,
        });
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const items = [
    {
      key: "1",
      label: "Job Vacancies",
      children: "SORRY, NO CURRENT VACANCIES",
    },
    {
      key: "2",
      label: "Consultants",
      children: "SORRY, NO CURRENT VACANCIES",
    },
    {
      key: "3",
      label: "Tender Notice",
      children: careerData.basic ? (
        <article
          dangerouslySetInnerHTML={{ __html: careerData.basic.basic_job_text }}
        ></article>
      ) : (
        ""
      ),
    },
    {
      key: "4",
      label: "Expression of Interest",
      children: "SORRY, NO CURRENT VACANCIES",
    },
  ];

  return (
    <>
      {!isLoading ? (
        <div>
          <ScrollTop />

          <BannerHalf
            image={`url('https://www.app.creasionventures.com/storage/${careerData.basic.basic_banner_image}')`}
            subtitle="Creasion Ventures"
            title={`<h3><strong>${careerData.basic.basic_title}</strong> </h3>`}
          />

          <div className="carrer_tab">
            <div className="container">
              <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
            </div>
          </div>
          {/* <div className="car_head">
            <div className="container">
              <article
                dangerouslySetInnerHTML={{
                  __html: careerData.basic.basic_title,
                }}
              ></article>

              <article
                dangerouslySetInnerHTML={{
                  __html: careerData.basic.basic_subtitle,
                }}
              ></article>

              <NavLink to="/vacancy">See all job openings</NavLink>
            </div>
          </div>

          <BecomeRecycler /> */}
        </div>
      ) : (
        <Preloader />
      )}
    </>
  );
};

export default Careers;
