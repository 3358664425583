import React, { useState, useEffect } from "react";
import BannerHalf from "../../components/Banner/BannerHalf";

import ScrollTop from "../../components/ScrollTop";
import ProblemAccordian from "./ProblemAccordian";

import Preloader from "../../components/Preloader/Preloader";
import "./Problems.scss";

const RegionalProblems = () => {
  const [problemData, setProblemData] = useState([]);
  const fetchData = () => {
    fetch("https://www.app.creasionventures.com/api/problems")
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        let problemApi = data.data;

        const rslt = problemApi.problems.filter(
          (data) => data.title === "National Problem"
        );

        setProblemData(rslt);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      {problemData[0] ? (
        <>
          <ScrollTop />

          <BannerHalf
            image="url(./images/regional_problem.jpg)"
            subtitle="Problems"
            title="<h3>Regional <strong>Problems</strong> </h3>"
          />

          <section className="problems">
            <div className="container">
              <ProblemAccordian data={problemData[0].problems} />
            </div>
          </section>
        </>
      ) : (
        <Preloader />
      )}
    </>
  );
};

export default RegionalProblems;
