import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";

import "./news.scss";

import Banner from "../../components/Banner/Banner";
import Preloader from "../../components/Preloader/Preloader";
// import NewsData from "./NewsData";
import BannerHalf from "../../components/Banner/BannerHalf";

import ScrollTop from "../../components/ScrollTop";
import Moment from "react-moment";

// const data = NewsData;

export const News = () => {
  const [newsData, setNewsData] = useState([]);

  const [isLoading, setIsLoading] = useState(true);

  const fetchData = () => {
    fetch("https://www.app.creasionventures.com/api/updates")
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        let newsApi = data.data;

        setNewsData(newsApi);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <ScrollTop />
      {!isLoading ? (
        <>
          <BannerHalf
            image="url(./images/cover.png)"
            title="<h3><strong>Updates</strong> </h3>"
          />

          <section className="news">
            <div className="container">
              <div className="wrap">
                {newsData.updates.items.map((val, index) => {
                  return (
                    <div className="news_card" key={index}>
                      <NavLink
                        className="news_wrap"
                        to="/newsDetail"
                        state={{ detail: val }}
                      >
                        <figure>
                          <img
                            src={`https://www.app.creasionventures.com/storage/${
                              JSON.parse(val.image)[0]
                            }`}
                            alt=""
                          />
                        </figure>

                        <div className="detail">
                          <h4>{val.title}</h4>
                          <Moment format="YYYY/MM/DD">
                            {val.published_date}
                          </Moment>
                        </div>
                      </NavLink>
                    </div>
                  );
                })}
              </div>
            </div>
          </section>
        </>
      ) : (
        <Preloader />
      )}
    </>
  );
};
