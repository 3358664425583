import React, { useEffect } from "react";
import { Spin } from "antd";

import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

import "antd/dist/antd.css";

import "./style.scss";

gsap.registerPlugin(ScrollTrigger);

const Preloader = () => {
  useEffect(() => {
    gsap.fromTo(
      ".preloader_fixed img",
      {
        x: -50,
      },
      { duration: 2, x: 100 }
    );
  });

  return (
    <div className="preloader_fixed">
      {/* <Spin size="large" /> */}

      <div>
        <img src="./images/truck.png" alt="" />
      </div>
    </div>
  );
};

export default Preloader;
