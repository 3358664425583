import React from "react";

import { useLocation } from "react-router-dom";
import BannerHalf from "../../components/Banner/BannerHalf";
import ScrollTop from "../../components/ScrollTop";

const MilestoneDetail = () => {
  const location = useLocation();
  const { detail } = location.state;

  return (
    <>
      <ScrollTop />

      <BannerHalf
        image={`url('https://www.app.creasionventures.com/storage/${detail.image}')`}
        subtitle={`Milestone | ${detail.year}`}
        title={detail.title}
      />

      <section className="ms_content">
        <article
          dangerouslySetInnerHTML={{ __html: detail.description }}
        ></article>
      </section>
    </>
  );
};

export default MilestoneDetail;
