import React, { useState, useRef, useEffect } from "react";
import { Row, Col } from "antd";
import BannerHalf from "../../components/Banner/BannerHalf";
import Preloader from "../../components/Preloader/Preloader";

import {
  Form,
  Input,
  Button,
  InputNumber,
  DatePicker,
  notification,
  Select,
} from "antd";

import axios from "axios";

import "./carrer.scss";
import ScrollTop from "../../components/ScrollTop";

const BecomeRecyclerSaathi = () => {
  const [isLoading, setIsLoading] = useState(true);

  const [locationData, setLocationData] = useState([]);
  const [pageData, setPageData] = useState({
    items: null,
  });

  const myForm = useRef();

  const fetchData = () => {
    fetch("https://www.app.creasionventures.com/api/location")
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        let locationData = data.data;

        setLocationData(locationData);
      });

    fetch("https://www.app.creasionventures.com/api/careers")
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        let careerApi = data.data;

        setPageData({
          items: careerApi.career.items[1],
        });

        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  let optionsArr = [];

  if (locationData.length > 0) {
    locationData.forEach((data) => {
      const labelData = data.title;
      const valueData = data.title;

      optionsArr.push({
        value: valueData,
        label: labelData,
      });
    });
  }

  const onFinish = (values) => {
    values.formData.form_type = "become_recycler_saathi";
    // values.formData.scheduled_drop_off_date = values.formData.schedule_time._d;

    setIsLoading(true);
    axios
      .post("https://www.app.creasionventures.com/api/contact", values)
      .then((response) => {
        if (response.data.errors) {
          response.data.errors.forEach((error) => {
            notification["error"]({
              description: error,
            });
          });
        } else {
          notification["success"]({
            description: response.data.message,
          });

          myForm.current.resetFields();
        }

        setIsLoading(false);
      });
  };

  return (
    <>
      <ScrollTop />

      {pageData.items ? (
        <BannerHalf
          image={`url('https://www.app.creasionventures.com/storage/${pageData.items.image}')`}
          subtitle="Carrer"
          title={`<h3><strong>${pageData.items.title}</strong></h3>`}
        />
      ) : (
        ""
      )}
      {isLoading ? <Preloader /> : ""}

      <div className="form_wrapper">
        <div className="container">
          <div className="head">
            {pageData.items ? (
              <>
                <h3>
                  {pageData.items.title_light}
                  <strong> {pageData.items.title_dark}</strong>
                </h3>

                <article
                  dangerouslySetInnerHTML={{
                    __html: pageData.items.body_description,
                  }}
                ></article>
              </>
            ) : (
              ""
            )}
          </div>

          <div className="form">
            <Form
              name="Become a Recycler Saathi"
              ref={myForm}
              onFinish={onFinish}
            >
              <div className="form_card">
                <Form.Item
                  initialValue={`Become a Recycler Saathi`}
                  name={["formData", "type"]}
                  hidden={true}
                >
                  <Input />
                </Form.Item>
              </div>

              <Row>
                <Col span={12}>
                  <div className="form_card">
                    <Form.Item
                      name={["formData", "full_name"]}
                      label="Full Name"
                      rules={[
                        {
                          required: true,
                          message: "Please input your full name",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </div>
                </Col>

                <Col span={12}>
                  <div className="form_card">
                    <Form.Item
                      name={["formData", "contact_number"]}
                      label="Contact Number"
                      rules={[
                        {
                          required: true,
                          message: "Please input your contact number!",
                        },
                      ]}
                    >
                      <InputNumber />
                    </Form.Item>
                  </div>
                </Col>

                <Col span={12}>
                  <div className="form_card">
                    <Form.Item
                      name={["formData", "address"]}
                      label="Address"
                      rules={[
                        {
                          required: true,
                          message: "Please select your location!",
                        },
                      ]}
                    >
                      <Select
                        options={optionsArr}
                        placeholder="Select Recollection Center"
                      />
                    </Form.Item>
                  </div>
                </Col>

                <Col span={12}>
                  <div className="form_card">
                    <Form.Item
                      name={["formData", "email"]}
                      label="Email"
                      rules={[
                        {
                          required: true,
                          type: "email",
                          message: "Please input your email address",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </div>
                </Col>

                <Col span={24}>
                  <div className="form_card">
                    <Form.Item
                      name={["formData", "message"]}
                      label="Your Message"
                    >
                      <Input.TextArea />
                    </Form.Item>
                  </div>
                </Col>

                <Col span={8}>
                  <div className="form_card">
                    <Form.Item
                      name={["formData", "scheduled_drop_off_date"]}
                      label="Schedule a drop off"
                      rules={[
                        {
                          required: true,
                          message: "Please specify drop off date and time ",
                        },
                      ]}
                    >
                      <DatePicker showTime />
                    </Form.Item>
                  </div>
                </Col>
              </Row>

              <div className="submit_btn">
                {/* <button>Submit Request</button> */}

                <Form.Item>
                  <Button type="primary" htmlType="submit">
                    Submit Request
                  </Button>
                </Form.Item>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
};

export default BecomeRecyclerSaathi;
