import React, { useState, useRef, useEffect } from "react";
import BannerHalf from "../../components/Banner/BannerHalf";
import ScrollTop from "../../components/ScrollTop";

import axios from "axios";

import {
  Form,
  Input,
  Button,
  InputNumber,
  notification,
  Modal,
  Select,
} from "antd";
import { DownloadOutlined } from "@ant-design/icons";

import "./download.scss";
import Preloader from "../../components/Preloader/Preloader";

const { Option } = Select;

const Download = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const [isLoading, setIsLoading] = useState(true);

  const [mainData, setMainData] = useState({
    basic: null,
    downloadData: [],
  });

  const fetchData = () => {
    fetch("https://www.app.creasionventures.com/api/download")
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        let downloadApi = data.data.download;

        setMainData({
          basic: downloadApi.basic,
          downloadData: downloadApi.downloadData,
        });

        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const myForm = useRef();

  const showModal = (e, active) => {
    e.preventDefault();
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const onFinish = (values) => {
    // console.log(values);
    axios
      .post("https://www.app.creasionventures.com/api/access-reports", values)
      .then((response) => {
        if (response.data.errors) {
          response.data.errors.forEach((error) => {
            notification["error"]({
              description: error,
            });
          });
        } else {
          notification["success"]({
            description: response.data.message,
          });

          myForm.current.resetFields();
          setIsModalVisible(false);
        }

        setIsLoading(false);
      });
  };

  return (
    <>
      {!isLoading ? (
        <div>
          <ScrollTop />

          <Modal
            className="download_modal"
            title="Request Recycler Saathi Report Download"
            visible={isModalVisible}
            // onOk={handleOk}
            onCancel={handleCancel}
          >
            <Form ref={myForm} onFinish={onFinish}>
              <div className="form_card">
                <Form.Item
                  name={["formData", "full_name"]}
                  label="Full Name"
                  rules={[
                    {
                      required: true,
                      message: "Please input your full name ",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </div>

              <div className="form_card">
                <Form.Item
                  name={["formData", "num"]}
                  label="Phone Number"
                  rules={[
                    {
                      required: true,
                      message: "Please input your contact Number!",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </div>

              <div className="form_card">
                <Form.Item
                  name={["formData", "email"]}
                  label="Email"
                  rules={[
                    {
                      required: true,
                      type: "email",
                      message: "Please input your email address!",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </div>

              <div className="form_card">
                <Form.Item
                  name={["formData", "reports"]}
                  label="Select Reports"
                  rules={[
                    {
                      required: true,
                      message: "Please select atleast one report!",
                    },
                  ]}
                >
                  <Select mode="multiple" optionLabelProp="label">
                    {mainData.downloadData.map((val, index) => {
                      return (
                        <Option value={val.title} label={val.title} key={index}>
                          <div className="demo-option-label-item">
                            {val.title}
                          </div>
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </div>

              <div className="form_card">
                <p>
                  Note: Selected reports will be sent in above requested email
                  once our team will verify your query
                </p>
              </div>

              <div className="submit_btn">
                {/* <button onClick={submitRequest}>Submit Request</button> */}
                <Form.Item>
                  <Button type="primary" htmlType="submit">
                    Submit request
                  </Button>
                </Form.Item>
              </div>
            </Form>
          </Modal>

          <BannerHalf
            image={`url('https://www.app.creasionventures.com/storage/${mainData.basic.basic_banner_image}')`}
            subtitle="Resources"
            title={`<h3><strong>${mainData.basic.basic_title}</strong> </h3>`}
          />

          <div className="download_list">
            <div className="container">
              <div className="dl_wrap">
                {mainData.downloadData.map((val, index) => {
                  return (
                    <div className="dl_card" key={index}>
                      <a href="" onClick={(e) => showModal(e, val)}>
                        <img
                          src={`https://www.app.creasionventures.com/storage/${val.img}`}
                          alt=""
                        />
                        <div>
                          <h4>{val.title}</h4>
                          {/* <DownloadOutlined /> */}
                        </div>
                      </a>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Preloader />
      )}
    </>
  );
};

export default Download;
