import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";

import Map, { ScaleControl } from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import ScrollTop from "../../components/ScrollTop";

import mapboxgl from "mapbox-gl";
import { Row, Col } from "antd";

import { Source, Layer } from "react-map-gl";

import "mapbox-gl/dist/mapbox-gl.css";
import ProvinceLabel from "./data/ProvinceLabel.geojson";
import ProvinceMap from "./data/ProvinceMap.json";

// import recyclerSaathiData2 from "./data/mainData/AllData.geojson";

import Preloader from "../../components/Preloader/Preloader";

import { provinceLabelStyle, mapStyleLine } from "./PortalStyle";

import "./portal.scss";
import DataCharts from "./DataCharts";

import { CloseOutlined } from "@ant-design/icons";

// The following is required to stop "npm build" from transpiling mapbox code.
// notice the exclamation point in the import.
// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax, import/no-unresolved
mapboxgl.workerClass = require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

const Portal = () => {
  const [portalData, setPortalData] = useState({
    portalBasic: null,
    portalMap: null,
    coordinateData: null,
  });

  const [recyclerSaathiData, setRecyclerSaathiData] = useState({
    type: "FeatureCollection",
    features: [
      {
        type: "Feature",
        properties: {
          title: "",
        },
        geometry: {
          type: "Point",
          coordinates: [],
        },
      },
    ],
  });

  const [isLoading, setIsLoading] = useState(true);

  const [navData, setNavData] = useState([
    {
      title: "",
    },
  ]);

  const [sideBar, setSideBar] = useState({
    isOpen: true,
    title: "",
    text: "",
  });

  const [dataStyle, setDataStyle] = useState({
    id: "",
    type: "circle",
    filter: ["==", "title", `Collection Partners`],
    paint: {
      "circle-radius": 8,
      "circle-stroke-width": 1,
      "circle-color": [
        "match",
        ["get", "title"],
        "Organisational Network",
        "#EA5C2B",
        "PET recycling center",
        "#D9CE3F",
        "Recycler Saathi Center",
        "#10b5c9",
        "Volunteer For Change",
        "#573391",
        "Collection Partners",
        "#EF6D6D ",
        "Waste Smart Schools",
        "#219F94",
        "Waste Smart Musuem",
        "red",
        /* other */ "#BD1E1E",
      ],
    },
  });

  let temp = [];

  const fetchData = async () => {
    const response = await fetch(
      "https://www.app.creasionventures.com/api/portal"
    );
    const responseData = await response.json();

    if (responseData) {
      let portalApi = responseData.data;

      setPortalData({
        portalBasic: portalApi.chart,
        portalMap: portalApi.map,
        coordinateData: portalApi.map.coordinates,
      });

      const dataTitle = portalApi.map.mapData;

      dataTitle.forEach((data) => {
        const mapTitle = data.title;
        const mapColor = data.color;

        temp.push(mapTitle, mapColor);
      });

      setDataStyle({
        id: "",
        type: "circle",
        filter: ["==", "title", `Collection Partners`],
        paint: {
          "circle-radius": 8,
          "circle-stroke-width": 1,
          "circle-color": [
            "match",
            ["get", "title"],
            ...temp,
            /* other */ "#BD1E1E",
          ],
        },
      });

      setIsLoading(false);

      setNavData(portalApi.map.mapData);

      setSideBar({
        isOpen: true,
        title: portalApi.map.mapData[3].title,
        text: portalApi.map.mapData[3].text,
      });

      portalApi.map.coordinates.forEach((item) => {
        let feature = {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [item.longitude, item.latitude],
          },
          properties: { title: item.portal.title },
        };

        recyclerSaathiData.features.push(feature);
      });
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const [calcShow, setCalcShow] = useState(0);

  function closeModal() {
    window.location.reload(false);
    setCalcShow(0);
  }

  function refreshPage() {
    setCalcShow(1);
    const script = document.createElement("script");
    script.src = scriptSrc;
    script.async = false;
    document.body.appendChild(script);
  }

  const [viewport, setViewport] = useState({
    latitude: 28.3534542,
    longitude: 82.2835325,
    minZoom: 6.3,
    maxZoom: 20,
  });

  const [zoomValue, setZoomValue] = useState(6.3);

  useEffect(() => {
    if (window.innerWidth < 1400) {
      setZoomValue(6);
      setViewport({
        minZoom: 5.5,
        zoom: 6,
      });
    }

    if (window.innerWidth < 700) {
      setZoomValue(5);
      setViewport({
        minZoom: 4.5,
        zoom: 5,
      });
    }
  }, []);

  const MAPBOX_TOKEN =
    "pk.eyJ1IjoieW9nZXNoa2Fya2kiLCJhIjoiY2txZXphNHNlMGNybDJ1cXVmeXFiZzB1eSJ9.A7dJUR4ppKJDKWZypF_0lA";

  const [isExpand, setIsExpand] = useState(3);
  const [showReset, setShowReset] = useState(1);

  const navClick = (index, val) => {
    setIsExpand(index);

    setViewport({
      latitude: 28.3534542,
      longitude: 82.2835325,
    });

    setSideBar({
      isOpen: true,
      title: val.title,
      text: val.text,
    });

    setDataStyle({
      id: "",
      type: "circle",
      filter: ["==", "title", `${val.title}`],
      paint: {
        "circle-radius": 8,
        "circle-stroke-width": 1,
        "circle-color": [
          "match",
          ["get", "title"],
          ...temp,
          /* other */ "#BD1E1E",
        ],
      },
    });

    setShowReset(1);
  };

  const resetData = () => {
    setIsExpand(-1);
    setSideBar({
      isOpen: false,
    });

    setViewport({
      latitude: 28.3534542,
      longitude: 83.9835325,
      minZoom: 5,
      zoom: zoomValue,
      maxZoom: 20,
    });
    setDataStyle({
      id: "",
      type: "circle",
      paint: {
        "circle-radius": 8,
        "circle-stroke-width": 1,
        "circle-color": [
          "match",
          ["get", "title"],
          ...temp,
          /* other */ "#BD1E1E",
        ],
      },
    });

    setShowReset(null);
  };

  let ResetDiv = () => {
    return (
      <div className="reset_data" onClick={resetData}>
        <CloseOutlined />
      </div>
    );
  };

  const scriptSrc = "https://cdn.omnicalculator.com/sdk.js";

  return (
    <>
      {!isLoading ? (
        <div>
          <loadDynamicScript />
          <ScrollTop />

          <div className="portal">
            <Map
              {...viewport}
              style={{ width: "100vw", height: "88%" }}
              mapboxAccessToken={MAPBOX_TOKEN}
              mapStyle="mapbox://styles/yogeshkarki/cl0gcwa4e000d15p3bh6inkcq"
              onMove={(evt) => setViewport(evt.viewport)}
            >
              <Source id="provinceLabel" type="geojson" data={ProvinceLabel}>
                <Layer {...provinceLabelStyle} />
              </Source>

              <Source id="nepalMap" type="geojson" data={ProvinceMap}>
                <Layer {...mapStyleLine} />
              </Source>

              <Source id="allData" type="geojson" data={recyclerSaathiData}>
                <Layer {...dataStyle} />
              </Source>

              <ScaleControl />
            </Map>

            <div className="legend_click">
              {navData.map((val, index) => {
                return (
                  <div
                    className={`${isExpand === index ? "expand" : ""} card `}
                    key={index}
                    onClick={() => navClick(index, val)}
                  >
                    <div className="num">
                      <h3>
                        {val.map_data_count < 10
                          ? `0${val.map_data_count}`
                          : val.map_data_count}
                      </h3>
                    </div>

                    <div className="desc">
                      <h4>{val.title}</h4>
                    </div>
                    <span
                      className="border"
                      style={{ backgroundColor: val.color }}
                    ></span>
                  </div>
                );
              })}
            </div>

            <div
              className={`${
                sideBar.isOpen == true ? "open" : ""
              } legend_detail `}
            >
              {showReset ? <ResetDiv /> : ""}
              <div className="contain">
                <h3>
                  <strong>{sideBar.title}</strong>{" "}
                </h3>
                <article
                  dangerouslySetInnerHTML={{ __html: sideBar.text }}
                ></article>
              </div>
            </div>
          </div>

          <section className="charts">
            <DataCharts data={portalData.portalBasic} />
          </section>

          <section className="footprint">
            <div className="container">
              <Row gutter={[50, 15]} type="flex">
                <Col lg={12} sm={24}>
                  <figure className="plastic_footprint">
                    <img src="/images/become_saathi/calculator.jpg" alt="" />
                    <div className="text">
                      <h3>
                        Discover your <strong>Plastic footprint</strong>
                      </h3>
                      <a href="#" onClick={refreshPage}>
                        Calculate
                      </a>
                    </div>
                  </figure>
                </Col>

                <Col lg={12} sm={24}>
                  <figure className="plastic_footprint">
                    <img
                      src="/images/become_saathi/Become_a_Recycler_Saathi.jpg"
                      alt=""
                    />
                    <div className="text">
                      <h3>
                        Become a <strong>Recycler Saathi</strong>
                      </h3>

                      <NavLink to="/become_recycler_saathi">Join Us</NavLink>
                    </div>
                  </figure>
                </Col>
              </Row>

              <div className={`${calcShow == 1 ? "open" : ""} omni_calc `}>
                <span className="close_calc" onClick={closeModal}>
                  <CloseOutlined />
                </span>
                <div
                  className="omni-calculator"
                  data-calculator="ecology/plastic-footprint"
                  data-width="500"
                  data-config="{}"
                  data-currency="EUR"
                  data-show-row-controls="false"
                  data-version="3"
                  data-t="1649068266810"
                >
                  <div className="omni-calculator-header">
                    Discover your Plastic Footprint
                  </div>
                  <div className="omni-calculator-footer">
                    <a
                      href="https://www.omnicalculator.com/ecology/plastic-footprint"
                      target="_blank"
                    >
                      <img
                        alt="Omni"
                        className="omni-calculator-logo"
                        src="https://cdn.omnicalculator.com/embed/omni-calculator-logo-long.svg"
                      />
                    </a>
                  </div>
                </div>
                {/* <CalcLoad /> */}
              </div>

              {/* <CalcLoad /> */}
            </div>
          </section>
        </div>
      ) : (
        <Preloader />
      )}
    </>
  );
};

export default Portal;
