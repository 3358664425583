import React, { useEffect, useState } from "react";
import { Table } from "antd";
import BannerHalf from "../../components/Banner/BannerHalf";

import ScrollTop from "../../components/ScrollTop";
import "./carrer.scss";

import Preloader from "../../components/Preloader/Preloader";

const Vacancy = () => {
  const [careerData, setCareerData] = useState({
    basic: null,
    items: null,
    jobs: null,
  });

  const [isLoading, setIsLoading] = useState(true);

  const fetchData = () => {
    fetch("https://www.app.creasionventures.com/api/careers")
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        let careerApi = data.data;

        setCareerData({
          basic: careerApi.career.basic,
          items: careerApi.career.items,
          jobs: careerApi.career.jobs,
        });
        setIsLoading(false);

        careerApi.career.jobs.forEach((job) => {
          const tableBody = document.querySelector(".vacancy_table tbody");

          let tableRow = `
          <tr>
         
            <td>${job.post}</td>
            <td>${job.deadline}</td>
            <td><a href=${job.link} target="_blank" className="apply_btn">Apply Now</a> </td>
          </tr>
          `;

          tableBody.insertAdjacentHTML("afterBegin", tableRow);
        });
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      {!isLoading ? (
        <div>
          <ScrollTop />
          <BannerHalf
            image={`url('https://www.app.creasionventures.com/storage/${careerData.basic.basic_banner_image}')`}
            subtitle="RECYCLE SAATHI"
            title={`<h3><strong>Careers</strong> </h3>`}
          />

          <section className="job_table">
            <div className="container">
              <article
                dangerouslySetInnerHTML={{
                  __html: careerData.basic.basic_subtitle_text,
                }}
              ></article>

              {careerData.jobs.length > 0 ? (
                <div>
                  <div className="table_post">
                    <table className="vacancy_table">
                      <thead>
                        <tr>
                          <th>Post</th>
                          <th>Deadline</th>
                          <th>Post</th>
                        </tr>
                      </thead>

                      <tbody></tbody>
                    </table>
                  </div>{" "}
                </div>
              ) : (
                ""
              )}
            </div>
          </section>
        </div>
      ) : (
        <Preloader />
      )}
    </>
  );
};

export default Vacancy;
