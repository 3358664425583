import React from "react";

import { NavLink, useLocation } from "react-router-dom";
import ScrollTop from "../../components/ScrollTop";
import BannerHalf from "../../components/Banner/BannerHalf";

const ProgramDetail = () => {
  const location = useLocation();
  const { detail } = location.state;

  return (
    <>
      <ScrollTop />

      <BannerHalf
        image={`url('https://www.app.creasionventures.com/storage/${detail.image}')`}
        subtitle={`Our Programs`}
        title={detail.title}
      />

      <section className="ms_content">
        <p>{detail.short_description}</p>

        <article
          dangerouslySetInnerHTML={{ __html: detail.description }}
        ></article>
      </section>

      <section className="see_other">
        <NavLink to="/programs">View our programs</NavLink>
      </section>
    </>
  );
};

export default ProgramDetail;
