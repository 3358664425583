import React from "react";
import { useLocation } from "react-router-dom";

import BannerHalf from "../../components/Banner/BannerHalf";
import ScrollTop from "../../components/ScrollTop";

const MediaDetail = () => {
  const location = useLocation();
  const { detail } = location.state;

  const printData = detail.coverage.filter((data) => data.type === "print");
  const onlineData = detail.coverage.filter((data) => data.type === "online");

  return (
    <>
      <ScrollTop />

      <BannerHalf
        image={`url('https://www.app.creasionventures.com/storage/${detail.image}')`}
        subtitle="Media"
        title={`<h3><strong>${detail.title} </strong> </h3>`}
      />

      <section className="media_lists">
        <div className="container">
          {printData.length > 0 ? (
            <>
              <div className="media_head">
                <h3>
                  <strong>Print </strong>Coverage{" "}
                </h3>
              </div>

              <div className="media_card_wrap">
                {printData.map((val, index) => {
                  return (
                    <div className="media_card coverage" key={index}>
                      <a href={val.media_link} target="_blank">
                        <img
                          src={`https://www.app.creasionventures.com/storage/${val.media_img}`}
                          alt=""
                        />
                        <div className="desc">
                          <h4>{val.title}</h4>
                          <p>{val.date}</p>
                        </div>
                      </a>
                    </div>
                  );
                })}
              </div>
            </>
          ) : (
            ""
          )}

          {onlineData.length > 0 ? (
            <>
              <div className="media_head">
                <h3>
                  <strong>Online </strong>Coverage{" "}
                </h3>
              </div>

              <div className="media_card_wrap">
                {onlineData.map((val, index) => {
                  return (
                    <div className="media_card coverage" key={index}>
                      <a href={val.media_link} target="_blank">
                        <img
                          src={`https://www.app.creasionventures.com/storage/${val.media_img}`}
                          alt=""
                        />
                        <div className="desc">
                          <h4>{val.title}</h4>
                          <p>{val.date}</p>
                        </div>
                      </a>
                    </div>
                  );
                })}
              </div>
            </>
          ) : (
            ""
          )}
        </div>
      </section>
    </>
  );
};

export default MediaDetail;
