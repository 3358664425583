import React, { useEffect, useState } from "react";
import "antd/dist/antd.css";
import "./faq.scss";
import ScrollTop from "../../components/ScrollTop";
import { Collapse } from "antd";
import BannerHalf from "../../components/Banner/BannerHalf";
import Preloader from "../../components/Preloader/Preloader";

const { Panel } = Collapse;

export const Faq = () => {
  const [faqMainData, setFaqData] = useState([]);
  const fetchData = () => {
    fetch("https://www.app.creasionventures.com/api/about-us/faqs")
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        let faqapi = data.data;

        setFaqData(faqapi.faq);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      {faqMainData.basic ? (
        <div>
          <ScrollTop />

          <BannerHalf
            image={`url('https://www.app.creasionventures.com/storage/${faqMainData.basic.basic_banner_image}')`}
            subtitle={faqMainData.basic.basic_banner_subtitle}
            title={`<h3><strong>${faqMainData.basic.basic_banner_title}</strong> </h3>`}
          />

          <section className="faq">
            <div className="container">
              <Collapse accordion className="faq_collapse">
                {faqMainData.items.map((val, index) => {
                  return (
                    <Panel header={val.question} key={index}>
                      <article
                        dangerouslySetInnerHTML={{ __html: val.answer }}
                      ></article>
                    </Panel>
                  );
                })}
              </Collapse>
            </div>
          </section>
        </div>
      ) : (
        <Preloader />
      )}
    </>
  );
};
