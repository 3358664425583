import React, { useEffect } from "react";

import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

import NepalMap from "./NepalMap";

gsap.registerPlugin(ScrollTrigger);

const HomeAbout = (data) => {
  useEffect(() => {
    if (window.innerWidth > 500) {
      ScrollTrigger.create({
        trigger: ".abt .image",
        start: "top top+=5%",
        pin: ".abt .image .animate_svg",
        endTrigger: ".abt .wrap .textcard",
        end: "bottom center+=30%",
      });
    }
  });

  const dataText = data.data.items;

  return (
    <>
      <section className="abt">
        <div className="container">
          <div className="wrap">
            <div className="textcard">
              {dataText.map((val, index) => {
                return (
                  <div className="text" key={index}>
                    <article
                      dangerouslySetInnerHTML={{ __html: val.title }}
                    ></article>
                  </div>
                );
              })}
            </div>

            <div className="image">
              <div className="animate_svg">
                <NepalMap />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HomeAbout;
