import React from "react";

import "./plastic.scss";
const TimelineImage = (data) => {
  const tlData = data.data.items;

  return (
    <div className="dev_timeline">
      {tlData.map((val, index) => {
        return (
          <div className="tl_card" key={index}>
            <div className="date">
              <div className="big_circle"></div>
              <div className="small_circle"></div>
              <div className="line"></div>
              <h5>{val.timeline_date}</h5>
            </div>

            <div className="text">
              <h6>{val.title}</h6>
              <article dangerouslySetInnerHTML={{ __html: val.text }}></article>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default TimelineImage;
