import React, { useState, useEffect } from "react";
import ScrollTop from "../../components/ScrollTop";
import Preloader from "../../components/Preloader/Preloader";

import axios from "axios";
import "./style.scss";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/effect-fade";

import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

import BannerHalf from "../../components/Banner/BannerHalf";

gsap.registerPlugin(ScrollTrigger);

const PlasticRecycling = () => {
  const [prData, setPrData] = useState([]);
  const [prDetail, setPrDetail] = useState([]);
  const [prRecycle, setPrRecycle] = useState([]);
  const [activeVideo, setActiveVideo] = useState({
    index: 0,
    title: null,
    video: null,
  });

  const fetchData = () => {
    axios
      .get("https://www.app.creasionventures.com/api/plastic")
      .then((response) => {
        setPrData(response.data.data.plasticRecycling);
        setPrDetail(response.data.data.plasticRecycling.details);
        setPrRecycle(response.data.data.plasticRecycling.firstSection);
        setActiveVideo({
          index: 0,
          title: response.data.data.plasticRecycling.firstSection[0].title,
          video: response.data.data.plasticRecycling.firstSection[0].image,
        });
      })

      .catch((error) => console.log(error));
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    var items = document.querySelectorAll(".rec ul li");

    for (var i = 0, l = items.length; i < l; i++) {
      items[i].style.left =
        (
          40 -
          35 * Math.cos(-0.5 * Math.PI - 2 * (1 / l) * i * Math.PI)
        ).toFixed(4) + "%";

      items[i].style.top =
        (
          40 +
          35 * Math.sin(-0.5 * Math.PI - 2 * (1 / l) * i * Math.PI)
        ).toFixed(4) + "%";
    }
  });

  const activeSet = (e, val, index) => {
    e.preventDefault();

    setActiveVideo({
      index: index,
      title: val.title,
      video: val.image,
    });
  };

  // const Test = () => {
  //   setActiveVideo({
  //     index: 0,
  //     title: prData.firstSection[0].title,
  //     video: null,
  //   });
  //   return <></>;
  // };

  return (
    <>
      <ScrollTop />
      {prData.details ? (
        <div>
          <BannerHalf
            image={`url(https://www.app.creasionventures.com/storage/${prDetail.banner_image})`}
            subtitle={prDetail.banner_subtitle}
            title={`<h3>${prDetail.banner_title_light}<strong> ${prDetail.banner_title_dark}</strong> </h3>`}
          />

          {/* <Test /> */}

          <section className="pl_desc">
            <div className="container">
              <article
                dangerouslySetInnerHTML={{
                  __html: prDetail.banner_description,
                }}
              ></article>
            </div>
          </section>

          <section className="rec">
            <ul>
              {prRecycle.map((val, index) => {
                return (
                  <li key={index} onClick={(e) => activeSet(e, val, index)}>
                    <figure
                      className={index == activeVideo.index ? `active` : ""}
                    >
                      <img
                        src={`https://www.app.creasionventures.com/storage/${val.icon}`}
                        alt=""
                      />
                    </figure>
                    <h4>{val.title}</h4>
                  </li>
                );
              })}
            </ul>

            {activeVideo.index > -1 ? (
              <div className="video">
                <h3>
                  {activeVideo.title
                    ? ` Recycling steps for ` + activeVideo.title
                    : ""}{" "}
                </h3>

                <img
                  src={`https://www.app.creasionventures.com/storage/${activeVideo.video}`}
                  alt=""
                />
              </div>
            ) : (
              ""
            )}
          </section>
        </div>
      ) : (
        <Preloader />
      )}
    </>
  );
};

export default PlasticRecycling;
