import React, { useState, useEffect } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { Select } from "antd";
import VisibilitySensor from "react-visibility-sensor";
import CountUp from "react-countup";
// import PETData from "./PETData";

import "./portal.scss";
import { Progress } from "antd";

const { Option } = Select;

const DataCharts = (data) => {
  let PETData = [];

  const graphItems = data.data.items;

  graphItems.forEach((data) => {
    const dataY = data.y;
    const dataName = data.name;

    PETData.push({
      y: dataY,
      name: dataName,
    });
  });

  // console.log(PETData)

  const [filterData, setFilterData] = useState(PETData);

  let tl = [];

  filterData.forEach((data) => {
    let name = data.name;
    tl.push(name);
  });

  function handleChange(value) {
    if (value == "all") {
      return setFilterData(PETData);
    }

    const newFilterData = PETData.filter((data) => {
      return data.name.includes(value);
    });

    setFilterData(newFilterData);
  }

  const options = {
    chart: { type: "areaspline", animation: { duration: 2000 } },
    title: { text: "" },
    xAxis: { categories: tl },
    yAxis: { title: { text: "PET Recovery (Tons)" } },
    tooltip: { shared: true, valueSuffix: " tons" },
    plotOptions: {
      areaspline: {
        color: {
          linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
          stops: [
            [0, "#10B5C9"],
            [1, "#55c06473"],
          ],
        },
        fillOpacity: 0.5,
      },
    },
    series: [
      {
        name: "PET Recovery (Tons)",
        data: filterData,
      },
    ],
  };

  return (
    <>
      <section className="daily">
        <div className="container">
          <h3>
            Our <strong>Daily Tracker</strong>
          </h3>

          <VisibilitySensor partialVisibility offset={{ bottom: 200 }}>
            {({ isVisible }) => (
              <div className="wrap">
                <div className="sing">
                  <h2>
                    <strong>
                      {isVisible ? (
                        <CountUp
                          end={data.data.basic.basic_target_to_achieve}
                        />
                      ) : (
                        "0000"
                      )}
                    </strong>{" "}
                    <span>Tons</span>
                  </h2>
                  <h4>
                    Target to <strong>Achieve</strong>
                  </h4>
                </div>

                <div className="sing">
                  <h2>
                    <strong>
                      {isVisible ? (
                        <CountUp
                          start={1000}
                          end={data.data.basic.basic_achieved_target}
                        />
                      ) : (
                        "0000"
                      )}
                    </strong>{" "}
                    <span>Tons</span>
                  </h2>

                  <h4>
                    Target reached <strong>Till date</strong>
                  </h4>
                </div>

                <div className="sing">
                  {isVisible ? (
                    <Progress
                      type="circle"
                      percent={data.data.basic.basic_completed_percent}
                      format={(percent) =>
                        `${percent}% of our target is completed`
                      }
                    />
                  ) : (
                    <Progress
                      type="circle"
                      percent={0}
                      format={(percent) =>
                        `${percent}% of our target is completed`
                      }
                    />
                  )}
                </div>
              </div>
            )}
          </VisibilitySensor>
        </div>
      </section>

      <section className="portal_charts">
        <h3>
          Our <strong>Progress so Far</strong>
        </h3>
        <p>Recycler Saathi PET Recovered</p>

        <div className="wrap">
          <div className="line_chart">
            <div className="title">
              <Select defaultValue="all" onChange={handleChange}>
                <Option value="all">Till Now</Option>
                <Option value="2019">In 2019</Option>
                <Option value="2020">In 2020</Option>
                <Option value="2021">In 2021</Option>
                <Option value="2022">In 2022</Option>
                <Option value="2023">In 2023</Option>
              </Select>
            </div>

            <HighchartsReact highcharts={Highcharts} options={options} />
          </div>
        </div>
      </section>
    </>
  );
};

export default DataCharts;
