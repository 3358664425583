import React, { useEffect, useState } from "react";
import BannerHalf from "../../components/Banner/BannerHalf";
import ScrollTop from "../../components/ScrollTop";
import Preloader from "../../components/Preloader/Preloader";

import "./team.scss";
import { Helmet } from "react-helmet";

const Team = () => {
  const [teamData, setTeamData] = useState([]);
  const fetchData = () => {
    fetch("https://www.app.creasionventures.com/api/about-us/team")
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        let teamapi = data.data;

        setTeamData(teamapi);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      {teamData.team ? (
        <div>
          <Helmet>
            <meta charSet="utf-8" />
            <title>Team | Recycler Saathi</title>
            <meta
              name="description"
              content="When it comes to plastic recycling, every action counts!"
            />
            <link rel="canonical" href="https://recyclersaathi.org/team" />
          </Helmet>

          <ScrollTop />

          <BannerHalf
            image={`url('https://www.app.creasionventures.com/storage/${teamData.team.basic.basic_banner_image}')`}
            subtitle={teamData.team.basic.basic_banner_subtitle}
            title={`<h3><strong>${teamData.team.basic.basic_banner_title}</strong> </h3>`}
          />

          <section className="team">
            <div className="container">
              <div className="wrap">
                {teamData.team.items.map((val) => {
                  return (
                    <div className="team_card" key={val.name}>
                      <div className="tc_wrap">
                        <figure>
                          <img
                            src={`https://www.app.creasionventures.com/storage/${val.image}`}
                            alt=""
                          />
                        </figure>

                        <div className="detail">
                          <h4>{val.name}</h4>
                          <h5>{val.designation}</h5>
                          <h5>{val.degree}</h5>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </section>
        </div>
      ) : (
        <Preloader />
      )}
    </>
  );
};

export default Team;
