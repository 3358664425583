import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";

import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import ScrollTop from "../../components/ScrollTop";
import Preloader from "../../components/Preloader/Preloader";
import { Helmet } from "react-helmet";

gsap.registerPlugin(ScrollTrigger);

const Milestone = () => {
  const [milestoneDataMain, setMilestoneDataMain] = useState([]);
  const [MilestoneData, setMileStoneData] = useState([
    {
      title: "The Past ",
      presentation: [],
    },
    {
      title: "The Present",
      presentation: [],
    },
    {
      title: "The Future",
      presentation: [],
    },
  ]);

  const [activeData, setActiveData] = useState({
    title: null,
    image: null,
    content: null,
    data: null,
  });

  useEffect(() => {
    const milestoneBox = document.querySelector(".milestone");

    if (milestoneBox) {
      gsap.to(".milestone_sticky", {
        scrollTrigger: {
          trigger: ".milestone",
          start: "top top",
          pin: ".sticky_wrap",
          toggleActions: "play restart restart restart",
        },
      });
    }
  });

  const fetchData = () => {
    fetch("https://www.app.creasionventures.com/api/about-us/milestones")
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        let msdata = data.data;

        setMileStoneData([
          {
            title: "The Past ",
            presentation: msdata.milestone.items.past,
          },
          {
            title: "The Present ",
            presentation: msdata.milestone.items.present,
          },
          {
            title: "The Future ",
            presentation: msdata.milestone.items.future,
          },
        ]);

        setMilestoneDataMain(msdata.milestone);

        setActiveData({
          title: msdata.milestone.items.past[0].title,
          image: msdata.milestone.items.past[0].image,
          content: msdata.milestone.items.past[0].description,
          data: msdata.milestone.items.past[0],
        });
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const dataSetter = (list, index) => {
    setActiveData({
      title: list.title,
      image: list.image,
      content: list.description,
      data: list,
    });
  };

  return (
    <>
      {milestoneDataMain.basic ? (
        <div>
          <Helmet>
            <meta charSet="utf-8" />
            <title>Milestones | Recycler Saathi</title>
            <meta
              name="description"
              content="When it comes to plastic recycling, every action counts!"
            />
            <link
              rel="canonical"
              href="https://recyclersaathi.org/milestones"
            />
          </Helmet>

          <ScrollTop />

          <section className="milestone">
            <figure className="milestone_figure">
              <img
                src={`https://www.app.creasionventures.com/storage/${activeData.image}`}
                alt=""
              />
            </figure>

            <div className="milestone_content">
              <div className="container">
                <div className="milestone_wrap">
                  <div className="milestone_scroller">
                    <div className="milestone_head">
                      <h3>{milestoneDataMain.basic.basic_title}</h3>
                      <article
                        dangerouslySetInnerHTML={{
                          __html: milestoneDataMain.basic.basic_description,
                        }}
                      ></article>
                    </div>

                    <div className="milestone_listing">
                      {MilestoneData.map((val, index) => {
                        return (
                          <div className="list_single " key={index}>
                            <h4>{val.title}</h4>

                            <ul>
                              {val.presentation.map((list, index) => {
                                return (
                                  <li
                                    key={index}
                                    className={`${
                                      activeData.title === list.title
                                        ? "active"
                                        : ""
                                    } `}
                                    onClick={(e) => dataSetter(list, index)}
                                  >
                                    <p>{list.year}</p>
                                    <h5>{list.title}</h5>
                                  </li>
                                );
                              })}
                            </ul>
                          </div>
                        );
                      })}
                    </div>
                  </div>

                  <div className="hero_wrap">
                    <div className="sticky_wrap">
                      <div className="milestone_sticky">
                        <h3>{activeData.title} </h3>

                        <article
                          dangerouslySetInnerHTML={{
                            __html: activeData.content,
                          }}
                        ></article>

                        <NavLink
                          to="/milestone-detail"
                          state={{ detail: activeData.data }}
                        >
                          Read More
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      ) : (
        <Preloader />
      )}
    </>
  );
};

export default Milestone;
