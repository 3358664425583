import React, { useState, useEffect } from "react";
import "./solution.scss";

import BannerHalf from "../../components/Banner/BannerHalf";
import ScrollTop from "../../components/ScrollTop";

import Preloader from "../../components/Preloader/Preloader";

import axios from "axios";

const PolicyLevel = () => {
  const [solutionData, setSolutionData] = useState([]);

  const fetchData = () => {
    axios
      .get("https://www.app.creasionventures.com/api/solutions")
      .then((response) => {
        setSolutionData(response.data.data.policyLevelInterventions);
      })

      .catch((error) => console.log(error));
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <ScrollTop />

      {solutionData.items ? (
        <div>
          <BannerHalf
            image={`url(https://www.app.creasionventures.com/storage/${solutionData.basic.basic_banner_image})`}
            subtitle={solutionData.basic.basic_banner_subtitle}
            title={`<h3>${solutionData.basic.basic_banner_title_light}<strong> ${solutionData.basic.basic_banner_title_dark}</strong> </h3>`}
          />

          <div className="pl_solution">
            {solutionData.items.map((val, index) => {
              return (
                <div className="pls_card" key={index}>
                  <div className="container">
                    <h3>
                      <strong>{val.title_dark} </strong> {val.title_light}{" "}
                    </h3>

                    <article
                      dangerouslySetInnerHTML={{ __html: val.title }}
                    ></article>
                    <article
                      dangerouslySetInnerHTML={{ __html: val.text }}
                    ></article>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        <Preloader />
      )}
    </>
  );
};

export default PolicyLevel;
