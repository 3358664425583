import React from "react";

import { useLocation } from "react-router-dom";
import { CalendarOutlined } from "@ant-design/icons";

import ScrollTop from "../../components/ScrollTop";

import "./blogs.scss";

const Blogs = () => {
  const location = useLocation();
  const { detail } = location.state;

  return (
    <>
      <ScrollTop />

      <>
        <div className="blog_detail">
          <div className="wrap">
            <div className="blg_head">
              <h4>{detail.title}</h4>
              <p>
                By {detail.author} <CalendarOutlined /> {detail.published_date}
              </p>
            </div>

            <div className="blg_image">
              <img
                src={`https://www.app.creasionventures.com/storage/${detail.image}`}
                alt=""
              />
            </div>

            <div className="blg_text">
              <article
                dangerouslySetInnerHTML={{ __html: detail.description }}
              ></article>
            </div>
          </div>
        </div>
      </>
    </>
  );
};

export default Blogs;
