import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";

import "./header.scss";

const Header = () => {
  useEffect(() => {
    var menuBtn = document.querySelector(".mb_click");
    var navBox = document.querySelector(".nav_wrapper");

    menuBtn.addEventListener("click", () => {
      menuBtn.classList.toggle("close");
      navBox.classList.toggle("open");
    });

    if (window.innerWidth < 800) {
      var btns = document.querySelectorAll(
        ".nav_wrapper .sub_menu .close-nav-btn"
      );
      var btns2 = document.querySelectorAll(".direct_link a");

      function closeNav() {
        menuBtn.classList.remove("close");
        navBox.classList.remove("open");
      }

      btns.forEach((btn) => {
        btn.addEventListener("click", () => {
          closeNav();
        });
      });

      btns2.forEach((btn) => {
        btn.addEventListener("click", () => {
          closeNav();
        });
      });
    }
  });

  return (
    <>
      <header>
        <div className="wrap">
          <div className="logo direct_link">
            <NavLink to="/">
              <img src="./images/creasion-venture.png" />
            </NavLink>
          </div>

          <div className="mb_click ">
            <span></span>
            <span></span>
            <span></span>
          </div>

          <div className="nav_wrapper">
            <div className="primary">
              <nav>
                <ul>
                  <li className="direct_link">
                    <NavLink to="/about">
                      About Us  
                    </NavLink>
                  </li>

                  <li className="has_dropdown">
                    <a href="#">plastic</a>

                    <div className="sub_menu">
                      <div className="sm_wrap">
                        <NavLink to="/about_plastics" className="close-nav-btn">
                          About Plastics
                        </NavLink>
                        <NavLink
                          to="/plastic_industry"
                          className="close-nav-btn"
                        >
                          Plastic Industry
                        </NavLink>

                        <div className="menu_tree_div has_dropdown">
                          <a href="#">Problems</a>
                          <div className="menu_tree">
                            <NavLink
                              to="/global_problems"
                              className="close-nav-btn"
                            >
                              Global Problems
                            </NavLink>
                            <NavLink
                              to="/regional_problems"
                              className="close-nav-btn"
                            >
                              Regional Problems
                            </NavLink>
                            <NavLink
                              to="/national_problems"
                              className="close-nav-btn"
                            >
                              National Problems
                            </NavLink>
                          </div>
                        </div>

                        <div className="menu_tree_div has_dropdown">
                          <a href="#">Solutions</a>
                          <div className="menu_tree">
                            <NavLink
                              to="/policy_level_interventions"
                              className="close-nav-btn"
                            >
                              Policy Level Interventions
                            </NavLink>
                            <NavLink
                              to="/local_level_interventions"
                              className="close-nav-btn"
                            >
                              Local Level Interventions
                            </NavLink>
                          </div>
                        </div>

                        <NavLink
                          to="/plastic_recycling"
                          className="close-nav-btn"
                        >
                          Plastic Recycling
                        </NavLink>
                        {/* <NavLink
                          to="/world_without_waste_vision"
                          className="close-nav-btn"
                        >
                          World Without Waste Vision
                        </NavLink> */}
                      </div>
                    </div>
                  </li>

                  <li className="direct_link">
                    <NavLink to="/programs">
                      Projects  
                    </NavLink>
                  </li>

                  <li className="direct_link">
                    <NavLink to="/become_green_warrior">
                    BECOME A GREEN WARRIOR
                      <span className="blinker"></span>
                    </NavLink>
                  </li>
                </ul>
              </nav>
            </div>
            <div className="secondary">
              <nav>
                <ul>
                  {/* <li className="has_dropdown">
                    <a href="#">About Us</a>
                    <div className="sub_menu">
                      <div className="sm_wrap">
                        <NavLink to="/about" className="close-nav-btn">
                          About
                        </NavLink>
                        <NavLink to="/team" className="close-nav-btn">
                          Team
                        </NavLink>
                        <NavLink to="/programs" className="close-nav-btn">
                          Our Programs
                        </NavLink>
                        <NavLink to="/milestones" className="close-nav-btn">
                          Milestone
                        </NavLink>
                        <NavLink to="/faq" className="close-nav-btn">
                          Faqs
                        </NavLink>
                      </div>
                    </div>
                  </li> */}
                  <li className="direct_link">
                    <NavLink to="/careers" className="portal_btn">careers</NavLink>
                  </li>

                  {/* <li className="has_dropdown">
                    <a href="#">Resources</a>
                    <div className="sub_menu">
                      <div className="sm_wrap">
                        <NavLink to="/media" className="close-nav-btn">
                          Media
                        </NavLink>
                        <NavLink to="/news" className="close-nav-btn">
                          Updates
                        </NavLink>
                        <NavLink to="/blogs" className="close-nav-btn">
                          Blogs
                        </NavLink>
                        <NavLink to="/stories" className="close-nav-btn">
                          Stories
                        </NavLink>
                        <NavLink to="/download" className="close-nav-btn">
                          Downloads
                        </NavLink>
                      </div>
                    </div>
                  </li>

                  <li className="direct_link">
                    <NavLink to="/portal" className="portal_btn">
                      Portal
                    </NavLink>
                  </li> */}
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
